<template>
  <div class="container">
    <el-row class="nav-wrap" type="flex" justify="space-between">
      <el-col class="nav-wrap-left" :style="{ height: this.leftHeight }">
        <ul>
          <li :class="{ active: this.$route.name == 'cooperate-info' }" @click="$router.push('/my/cooperate-info')">
            合作信息
          </li>
          <li :class="{ active: this.$route.name == 'point-detail' }" @click="$router.push('/my/point-detail')">
            获得点值明细
          </li>
          <li
            :class="{ active: this.$route.name == 'my-order' || this.$route.name == 'order-detail' }"
            @click="$router.push('my-order')"
          >
            我的订单
          </li>
          <li :class="{ active: this.$route.name == 'address-manage' }" @click="$router.push('address-manage')">
            地址管理
          </li>
        </ul>
      </el-col>
      <el-col class="nav-wrap-right"> <router-view /> </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      leftHeight: 0,
      rightHeight: 0,
      actived: '',
    }
  },
  methods: {
    changeMenu() {},
    getHeight() {
      this.leftHeight = window.innerHeight - 230 + 'px'
      this.rightHeight = window.innerHeight - 270 + 'px'
    },
  },
  created() {
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
    if (this.$route.path === '/my') {
      this.$router.push('/my/cooperate-info')
    }
  },
}
</script>
<style lang="less" scoped>
.container {
  background-color: #f5f5f5;
  padding: 20px;
  padding-top: 30px;
}
.nav-wrap {
  width: 1200px;
  margin: 0 auto;
  &-left {
    width: 200px;
    height: auto;
    background-color: #ffffff;
    ul {
      margin-top: 20px;
    }
    ul li {
      font-size: 16px;
      line-height: 30px;
      height: 30px;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-left: 10px;
      margin: 10px 0;
      cursor: pointer;
    }
  }
  &-right {
    height: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    overflow: hidden;
  }
}
.active {
  border-left: 3px solid #ec7310;
  color: rgba(236, 115, 16, 1);
  background-image: linear-gradient(to right, #fff2e7, #f6f6f6);
}
</style>
